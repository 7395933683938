import React from "react";
import footerlogo from '../../assets/footerlogo.png';
import Logo from "../../assets/logotext.png";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import "./footer.css"
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="bg-clr uni-padding" >
  <div className="mb-5">
      <footer className="row col-lg-12 main-class mt-5">
        <div className="col-md-6 col-sm-12 footer-heading">
          <h1>PDFPlex-Trusted by Millions of Users</h1>
          <p className="text-muted mt-4" >Using PDFplex is the easiest way to edit PDF files. Our web app enables you to edit PDF files efficiently while keeping your data safe and secure.</p><br></br>
          <button className="footer-btn mt-4">Try For Free</button>
        </div>
        
        <div className="col-md-6 col-sm-12">
          <img src={footerlogo} className=" img-fluid" alt="logo" />
        </div>
      </footer>
      </div>
      <hr />
      <div className="bottom-footer mt-3 flex-wrap">
           <div className="d-flex flex-column gap-2">
           <Link to="/"> 
                <img src={Logo} className="image-logo" alt="logo" />
            </Link>
            <div className="gap-4 d-flex">
              <a href=""> <img src={facebook} alt="logo" width="10" /></a>
              <a href=""><img src={twitter} alt="logo" width="15" /></a>
              <a href=""><img src={facebook} alt="logo" width="10" /></a>
              <a href=""><img src={linkedin} alt="logo" width="15" /></a>
            </div>
           </div>
            <div className="footer-body">
              <Link className="btn-footer" to="/privacy-policy">Privacy Policy</Link>
              <Link className="btn-footer" to="/about">About</Link>
              <Link className="btn-footer" to="/disclaimer">Disclaimer</Link>
              <a className="btn-footer">Terms & Conditions</a>
              <a className="btn-footer">Help</a>
              <a href="https://www.google.com/" target="_blank" className="btn-footer">Contact Us</a>
            </div>
          </div>
    </div>
  )
}

export default Footer;