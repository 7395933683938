// Revise this page and remove it
import React, { useEffect, useState } from 'react';
import "./customize-page.css";
import Header from '../../components/header/Header';
import Imagebox from '../../components/pdfConveter/Imagebox';
import smallimg from "../../assets/small-margin-icon.png";
import bigimg from "../../assets/big-margin-icon.png";
import toast, { Toaster } from "react-hot-toast";
import rightarrow from "../../assets/rightarrow.png";
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import { REACT_APP_BASE_URL } from '../../config';
import { useParams, useLocation } from "react-router-dom";

export default function CustomizePage() {
  const [images, setImages] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [loader, setLoader] = useState("initial");
  const [fileUrl, setFileUrl] = useState("");
  const [selectedOrientation, setSelectedOrientation] = useState('landscape');
  const [selectedMargin, setSelectedMargin] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    orientation: '',
    width: '612',
    height: '792',
  });
  const base64ToFile = (base64String, fileName, mimeType) => {
    const byteString = atob(base64String.split(',')[1]);
    const mimeString = mimeType || 'image/jpeg'; // Default mime type if not provided
  
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    return new File([uint8Array], fileName, { type: mimeString });
  };
  useEffect(() => {
    const base64String = localStorage.getItem('base64String');
    const file = localStorage.getItem('file');
    const url = localStorage.getItem('url');
    const fileType = localStorage.getItem('fileType');
    const id = localStorage.getItem('id');
  
    if (base64String && url) {
      // Assuming a default filename if not stored
      const fileName = 'image.png'; // Replace with appropriate logic if filenames are available
  
      // Convert Base64 to File
      const fileObject = base64ToFile(base64String, fileName, fileType);
  
      setImages([{ base64String, file: fileObject, fileType, id, rotation: 0, url }]);
    }
  
    return () => {
      if (url) {
        URL.revokeObjectURL(url); // Clean up the URL object
      }
    };
  }, []);
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePageOrientationClick = (orientation) => {
    setSelectedOrientation(orientation);
    setFormData((prevData) => ({
      ...prevData,
      orientation: orientation,
    }));
  };

  const handleMarginSelection = (marginType) => {
    setSelectedMargin(marginType);
  };

  const submitImages = () => {
    setLoader("loading");
    const apiFormData = new FormData();
    images.forEach((image) => {
      apiFormData.append('files', image.file);
    });
    apiFormData.append('orientation', formData.orientation);
    apiFormData.append('width', formData.width);
    apiFormData.append('height', formData.height);
    const marginValue = selectedMargin === "small" ? 20 : selectedMargin === "big" ? 50 : 0;
    apiFormData.append('margin', marginValue);

    fetch(`${REACT_APP_BASE_URL}converter/${id}`, {
      method: "POST",
      body: apiFormData,
      redirect: "follow"
    })
      .then((res) => res.json())
      .then((res) => {
        setFileUrl(res.uploadedFile);
        setButtonStatus(true);
        if (res.status) {
          setLoader("end");
          toast.success(res?.message);
        } else {
          setLoader("initial");
          toast.error(res?.message);
        }
      });
  };

  useEffect(() => {
    if (loader === "end" && buttonStatus && fileUrl) {
      navigate("/convertor/file-ready", { state: { fileUrl } });
    }
  }, [fileUrl, loader, buttonStatus, navigate]);

  return (
    <>
      {loader === "loading" ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
            <div className='images-side ml-5'>
              <Imagebox images={images} setImages={setImages} filetype={formData.orientation} selectedMargin = {selectedMargin} selectedOrientation = {selectedOrientation}/>
            </div>
            <div className='converter-side p-3'>
              <h1 className='text-center'>{id}</h1>
              <div className='p-3 page-orientation'>
                <h6>Page orientation</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <div
                    className={`portrait ${selectedOrientation === 'portrait' ? 'selected' : ''}`}
                    onClick={() => handlePageOrientationClick('portrait')}
                  >
                    <p className='p-body'></p>
                    <p className='p-body-a'>Portrait</p>
                  </div>
                  <div
                    className={`landscape ${selectedOrientation === 'landscape' ? 'selected' : ''}`}
                    onClick={() => handlePageOrientationClick('landscape')}
                  >
                    <p className='l-body'></p>
                    <p className='l-body-a'>Landscape</p>
                  </div>
                </div>
              </div>
              <div className='p-3 page-orientation'>
                <h6>Margin</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <div
                    className={`img-body ${selectedMargin === 'no-margin' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('no-margin')}
                  >
                    <img src={smallimg} alt="logo" />
                    <p className='p-body-a'>No margin</p>
                  </div>
                  <div
                    className={`img-body ${selectedMargin === 'small' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('small')}
                  >
                    <img src={smallimg} alt="logo" />
                    <p className='l-body-a'>Small</p>
                  </div>
                  <div
                    className={`img-body ${selectedMargin === 'big' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('big')}
                  >
                    <img src={bigimg} alt="logo" />
                    <p className='l-body-a'>Big</p>
                  </div>
                </div>
              </div>
              <div className='mt-3 d-flex gap-3'>
                <p className='w-25 hello'>Width: </p>
                <select className='form-control' name="width" value={formData.width} onChange={handleChange}>
                  <option selected hidden>Select width</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="3000">3000</option>
                </select>
                <p className='w-25 hello'>Height: </p>
                <select className='form-control' name="height" value={formData.height} onChange={handleChange}>
                  <option selected hidden>Select height</option>
                  <option value="700">700</option>
                  <option value="1400">1400</option>
                  <option value="2100">2100</option>
                </select>
              </div>
              <div className="form-check form-switch p-0 d-flex justify-content-between mt-4">
                <p>Merge all images in one PDF file</p>
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
              </div>
              <div>
                <button className='btn-convert w-100' onClick={submitImages}>
                  Convert to PDF <img src={rightarrow} alt="" width="30" height="18" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Toaster />
    </>
  );
}
