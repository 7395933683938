import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import { api } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import Loader from '../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';

const PdfToJpg = ({ id, isSingle }) => {
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        quality: 'normal',
        extractImages: false,
        pageToJPG: false,
    });

    const convertFile = async (event) => {
        setMessage("");
        const selectedFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleSubmit = async () => {
        if (!files) {
          toast.error("Please select a file first.");
          return;
        }
    
        if (files?.length > 10) {
          toast.error("File must be less than or equal to 10.");
          return;
        }
    
        const invalidFiles = files.filter(file => file.size > MAX_FILE_SIZE);
        if (invalidFiles.length > 0) {
          toast.error("One or more files exceed the 10 MB limit.");
          return;
        }
    
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('file', files[0]);
        try {
          const response = await api().post(
            `pdf-to-image/single`, formDataToSend
          );
          setLoading(false);
          if (response?.data?.meta.status) {
            navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
          }
        } catch (error) {
          console.log("Error ===> ", error)
          throw error
        }
      };

    return (
        <>
            {files.length === 0 ? <>
                <FileHeader id={id} />
                <div className="d-flex justify-content-center">
                    <div className="main-box d-flex align-items-center justify-content-center mt-5">
                        <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                            <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                                <h3>Drop your file here</h3>
                                <span>or</span>
                                <div>
                                    <label
                                        id="idlabel"
                                        className="mdl-button mdl-js-button mdl-button--raised"
                                    >
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            multiple={!isSingle}
                                            id="exampleFormControlFile1"
                                            onChange={convertFile}
                                        />
                                        Upload a file
                                    </label>
                                </div>
                            </div>
                            <div>
                                <p className="text-muted mt-3">
                                    Maximum size allowed is 25MB. Supported formats are : {id.split("-")[0]}
                                </p>
                                {message && (
                                    <div className="text-success" style={{ color: "green" }}>
                                        {message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </> : <>
            {loading ? <Loader title={"Compressing " + replaceId(id)} /> : <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
                    <div className='images-side ml-5'>
                        <Imagebox files={files} setFiles={setFiles} type="pdf" isSingle={isSingle}/>
                    </div>
                    <div className='converter-side p-3'>
                        <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
                        <div className="main mt-4 p-0">
                            <div className="containerr d-flex align-items-center justify-content-between gap-5 p-3">
                                <div className="d-flex flex-column">
                                    <span className="title-form">Page to JPG</span>
                                    <span className="title-desc">Every page of this PDF will be converted into a JPG file. 1 JPG will be created.</span>
                                </div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="pageToJPG"
                                    checked={formData.pageToJPG}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-5 p-3" style={{ borderBottom: "1px solid #FF9291" }}>
                                <div className="d-flex flex-column">
                                    <span className="title-form">Extract Images</span>
                                    <span className="title-desc">Every page of this PDF will be converted into a JPG file. 1 JPG will be created.</span>
                                </div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="extractImages"
                                    checked={formData.extractImages}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='card-body d-flex justify-content-between mt-3'>
                                <button
                                    className={`portrait ${formData.quality === 'normal' ? 'selected' : ''}`}
                                    onClick={() => setFormData((prev) => ({ ...prev, quality: 'normal' }))}
                                >
                                    <p className='p-body-a' style={{ fontSize: '22px', fontWeight: '500', color: formData.quality === 'high' ? '#A7A7A7' : '#FF6E6C' }}>Normal
                                        <br />
                                        <span style={{ fontSize: '16px', fontWeight: '300', color: formData.quality === 'high' ? '#A7A7A7' : '#FF6E6C' }}>Recommended</span>
                                    </p>
                                </button>
                                <button
                                    className={`landscape ${formData.quality === 'high' ? 'selected' : ''}`}
                                    onClick={() => setFormData((prev) => ({ ...prev, quality: 'high' }))}
                                >
                                    <p className='l-body-a' style={{ fontSize: '22px', fontWeight: '500', color: formData.quality === 'high' ? '#FF6E6C' : '#A7A7A7' }}>High</p>
                                </button>
                            </div>
                            <br />
                            <button className='btn-convert w-100' onClick={handleSubmit} disabled={loading}>
                                Convert to JPG <img src={rightarrow} alt="" width="30" height="18" />
                            </button>
                        </div>
                    </div>
                </div>}
            </>}
            {files.length === 0 ? <>
                <FileFooter id={id} />
                <Footer />
            </> : <>Add</>}
            <ToastContainer />
        </>
    );
};

export default PdfToJpg;
