import React from "react";
import "./erorrPage.css";
import image from "../../../src/assets/Group 2252 (1).svg"

const ErrorPage = () => {
    return (
        <>
            <div className="main-screen">
                <div className="center-screen">
                    <img src={image} alt="" />
                    <h2>
                        Oh no! We couldn't process your file error screen
                    </h2>
                    <p>
                        Server error occurred and it's our fault. Please try again latter.
                    </p>
                    <span>
                        We are currently experiencing some server problems, we will fix it as soon as possible. Please <div className="red">
                            retry in a few minutes
                        </div>
                    </span>
                    <div className="buttons">
                        <button className="mt-4">
                            <div className="d-flex gap-4">
                                <div>Retry HTML to PDF</div>
                                <div> <i className="fa fa-light fa-arrow-right fa-sm"></i></div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

        </>
    )

}
export default ErrorPage;