import React, { useEffect, useState } from "react";
import data from "../../assets/statics/static-content.json";
import blue from "../../assets/blueimg.svg";
import icon from "../../assets/Polygon 1.svg";
import lineDesign from "../../assets/Line 3.svg";
import signup from "../../assets/services/image-to-pdf.svg";
import Accordion from "react-bootstrap/Accordion";
import Feature from "../feature/Feature";

function FileFooter ({id}) {
    const [staticData, setStaticData] = useState(null);

    useEffect(() => {
        setStaticData(data[id]);
      }, [id]);

    return(
        <> <div className="container mt-5 mb-5">
        <div className="row col-12">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img src={blue} className="img-fluid w-100" alt="logo" />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <h1 className="heading">
              How to convert {id.replaceAll("-", " ")} Free ?
            </h1>
            <div className="">
              <span className="d-flex gap-3">
                <span className=""><img src={icon} className="App-logo" alt="logo" /></span>
                <div className="trd-component-desc mt-4">
                  {staticData?.pointOne}
                </div>
              </span>
              <img src={lineDesign} className="line-desig" alt="logo" />

              <span className="d-flex gap-3">
                <img src={icon} className="App-logo" alt="logo" />
                <div className="trd-component-desc mt-4">
                  {staticData?.pointTwo}
                </div>
              </span>
              <img src={lineDesign} className="line-desig" alt="logo" />

              <span className="d-flex gap-3">
                <img src={icon} className="App-logo" alt="logo" />
                <div className="trd-component-desc mt-4">
                  {staticData?.pointThree}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h1 className="text-center heading">
              Easy & Secure {id.replaceAll("-", " ")} Online
            </h1>
            <div className="mt-4 trd-component-desc">
              {staticData?.subTitle}
            </div>
          </div>
        </div>
      </div>


      <div className="klZLFX container">
        <div className="iGqNlt ">
          {staticData?.cards?.map((val, index) => {
            return (
              <div className="dHcbLE" key={index}>
                <img src={signup} alt="logo" width="40" />
                <h3 className="EUhIa mt-4">{val.title}</h3>
                <p className="cThAAa">{val.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container d-flex gap-3 faq-body">
        <div className="frequently">
          <h3>Get Answer to some </h3>
          <p>Frequently Asked Questions</p>
        </div>
        <div className="qs">
          <Accordion className="mt-3 accordion d-flex flex-column gap-2" defaultActiveKey="0">
            {staticData?.faqs?.map((val, index) => {
              return (
                <Accordion.Item className="border accordion" eventKey={index} key={index}>
                  <Accordion.Header>{val.question}</Accordion.Header>
                  <Accordion.Body>{val.answer}</Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>

      <div className="container w-100">
        <div className="row d-flex gap-3">
          <span
            className="itemBox d-flex gap-3 mb-3"
            style={{ flexWrap: "wrap" }}
          >
            <Feature id={id} />
          </span>
          <div className="container middle-para mt-5 mb-5">
            I acknowledge Nitro's <span>Privacy Policy</span>. I represent and
            warrant that only my information is contained in any documents
            converted and give my consent to the processing of such information.
          </div>
        </div>
      </div></>
    )
}

export default FileFooter;