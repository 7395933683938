import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Remove `Switch` from import
import Convertor from './pages/convertor-section/ConvertorSection';
import Home from './pages/home/Home';
import FilesDetail from './pages/files-detail/FilesDetail';
import FileReady from './pages/file-ready/FileReady';
import ErrorPage from './components/404ErrorPage/ErrorPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomizePage from './pages/cutomize/Customize-page';
import About from './pages/about-us/About';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Disclaimer from './pages/disclaimer/Disclaimer';
import Htmltopdf from './pages/html-to-pdf/Htmltopdf';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/convertor/:id' element={<Convertor />} />
          <Route path='/convertor/files-detail' element={<FilesDetail />} />
          <Route path='/convertor/file-ready' element={<FileReady />} />
          {/* Route for 404 page */}
          <Route path='/customize/:id' element={<CustomizePage />} />
          <Route path='/htmltopdf' element={<Htmltopdf />} />
          <Route path='/about' element={<About/>}/>
          <Route path='/privacy-policy'element={ < PrivacyPolicy /> }/>
          <Route path='/disclaimer' element= { < Disclaimer /> } />
          <Route path='*' element={<ErrorPage />} />
         </Routes>
      </Router>
    </div>
  );
}

export default App;
