import React, {useEffect} from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import '../disclaimer/disclaimer.css'

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the window when the component mounts
});
return (
<>
<Header/>
<div className='main'>
  <p className='para-about'>Our Disclaimer page is intended to provide users with important information regarding the use of PDFplex and to clarify our responsibilities and limitations as a service provider. By using PDFplex, you agree to abide by the terms and conditions outlined in this disclaimer.</p>
<h1 className='heading-about'>Service Limitations:</h1>
<p className='para-about'>PDFplex is a service designed to facilitate document conversion and processing tasks, such as converting PDFs to other formats or compressing files. 
While we strive to provide accurate and reliable services, we cannot guarantee the completeness, accuracy, or reliability of the converted documents or the results of our services. Users are advised to review and verify the output of their converted documents to ensure accuracy and suitability for their intended purposes.
</p>

<h1 className='heading-about'>User Responsibility:</h1>
<p className='para-about'>
Users are solely responsible for the content they upload to PDFplex and any consequences arising from the use of our services. It is your responsibility to ensure that you have the necessary rights and permissions to upload and process the content, including any intellectual property rights or privacy considerations. PDFplex disclaims any liability for the misuse or unauthorized use of copyrighted materials or sensitive information uploaded by users.
</p>
<h1 className='heading-about'>Service Availability:</h1>
<p className='para-about'>While we strive to maintain high availability and uninterrupted access to PDFplex, we cannot guarantee that our services will be available 
  at all times or free from technical issues, interruptions, or delays. We may periodically perform maintenance, updates, or upgrades to improve the quality 
  and functionality of our services, which may result in temporary disruptions or downtime. PDFplex disclaims any liability for any loss 
  or damages resulting from service interruptions or unavailability.
</p>

<h1 className='heading-about'>Third-Party Content and Links:</h1>
<p className='para-about'>PDFplex may contain links to third-party websites, resources, or content that are not owned or controlled by us. 
    We do not endorse or assume any responsibility for the content, accuracy, or practices of third-party websites or services. 
    Users access and use third-party content or services at their own risk, and PDFplex disclaims any liability for any loss or damages 
    resulting from the use of third-party content or services.
</p>

<h1 className='heading-about'>No Warranty:</h1>
<p className='para-about'>PDFplex is provided on an "as is" and "as available" basis, without any warranties or representations, express or implied. 
We make no warranties regarding the accuracy, reliability, availability, or suitability of our services for any particular purpose. 
PDFplex disclaims all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
</p>

<h1 className='heading-about'>Limitation of Liability:</h1>
<p className='para-about'>To the fullest extent permitted by applicable law, PDFplex and its affiliates, officers, directors, 
      employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, 
      including but not limited to loss of profits, data, goodwill, or business interruption, arising out of or in connection with the use 
      or inability to use PDFplex or the reliance on any information or content provided therein.
</p>

<h1 className='heading-about'>Acceptance of Terms:</h1>
<p className='para-about'>By using PDFplex, you acknowledge and agree to the terms and conditions outlined in this Disclaimer. 
    If you do not agree with any part of this Disclaimer, you should refrain from using PDFplex.
</p>

</div>
<Footer/>
</>
)
}

export default Disclaimer