import React, { useEffect, useState } from "react";
import { features } from "../../utils";
import { useNavigate } from "react-router-dom";
import "./continue.css";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
const Continue = ()=>{
const navigate = useNavigate();
const handleClick = (id) => {
    navigate(`/convertor/${id}`);
    // Handle navigation to the next page with the given ID
  };
    return(
        <div className="row card-bodys p-3">
          <h1 className="">Continue to...</h1>
          <div className="mt-3 d-flex flex-wrap  gap-3 mb-3 " >
            {features.map((feature, index) => (
              <div className="cards p-3" onClick={() => handleClick(feature.id.replace(/ /g, '-'))}>
                <div className="d-flex align-items-center gap-3">
                <img src={feature.image} className="App-logo" alt="logo" />
                <h1 className="heading">{feature.name}</h1>
                </div>
                {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                <FontAwesomeIcon icon={faArrowRight} style={{fontSize:'25px'}}/>

              </div>
            ))}
          </div>
        </div>
    )
}
export default Continue