import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import noMargin from "../../assets/marginsetIcon.svg";
import bigimg from "../../assets/big-margin-icon.png";
import smallMargin from "../../assets/small-margin-icon.svg"
import { api } from "../../api/api";
import Loader from '../loader/Loader';
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const PngToPdf = ({ id }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    orientation: "portrait",
    margin: "none",
    pageSize: "A4",
    isMergeAll: false,
  });
  const navigate = useNavigate();
  const convertFile = async (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    return;
  }

  const handlePageOrientationClick = (e, orientation) => {
    e.preventDefault();
    setFormData(prevData => ({ ...prevData, orientation }));
  };

  const handleMarginSelection = (event, margin) => {
    event.preventDefault();
    setFormData(prevData => ({ ...prevData, margin }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (event) => {
    setFormData(prevData => ({ ...prevData, isMergeAll: event.target.checked }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!files) {
      toast.error("Please select a file first.");
      return;
    }

    if (files?.length > 10) {
      toast.error("File must be less than or equal to 10.");
      return;
    }

    const invalidFiles = files.filter(file => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      toast.error("One or more files exceed the 10 MB limit.");
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();
    Object.keys(formData)?.forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    // Append the file
    if (files.length > 1) {
      for (let i = 0; i < files.length; i++) {
        formDataToSend.append('files', files[i]);
      }
      try {
        const response = await api().post(
          `${id}/multiple`, formDataToSend
        );
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
        }
      } catch (error) {
        console.log("Error ===> ", error)
        throw error
      }
    } else {
      formDataToSend.append('file', files[0]);
      try {
        const response = await api().post(
          `${id}/single`, formDataToSend
        );
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
        }
      } catch (error) {
        toast.error(error.message);
        throw error
      }
    }
  };

  return (
    <>
      {files.length === 0 ? <>
        <FileHeader id={id} />
        <div className="d-flex justify-content-center">
          <div className="main-box d-flex align-items-center justify-content-center mt-5">
            <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
              <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                <h3>Drop your file here</h3>
                <span>or</span>
                <div>
                  <label
                    id="idlabel"
                    className="mdl-button mdl-js-button mdl-button--raised"
                  >
                    <input
                      type="file"
                      multiple
                      accept="image/png"
                      id="exampleFormControlFile1"
                      onChange={convertFile}
                    />
                    Upload a file
                  </label>
                </div>
              </div>
              <div>
                <p className="text-muted mt-3">
                  Maximum size allowed is 25MB. Supported formats are : {id.split("-")[0]}
                </p>
                {message && (
                  <div className="text-success" style={{ color: "green" }}>
                    {message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </> : <>
        {loading ? <Loader title={"Converting " + replaceId(id)} /> : <form onSubmit={handleSubmit}>
          <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
            <div className='images-side ml-5'>
              <Imagebox files={files} setFiles={setFiles} />
            </div>
            <div className='converter-side p-3'>
              <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
              <div className='p-3 page-orientation'>
                <h6>Page orientation</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <button
                    className={`portrait ${formData.orientation === 'portrait' ? 'selected' : ''}`}
                    onClick={(e) => handlePageOrientationClick(e, 'portrait')}
                    checked
                  >
                    <p className='p-body'></p>
                    <p className='p-body-a' style={{ color: formData.orientation === 'landscape' ? '#A7A7A7' : '#FF6E6C' }}>Portrait</p>
                  </button>
                  <button
                    className={`landscape ${formData.orientation === 'landscape' ? 'selected' : ''}`}
                    onClick={(e) => handlePageOrientationClick(e, 'landscape')}
                  >
                    <p className='l-body'></p>
                    <p className='l-body-a' style={{ color: formData.orientation === 'landscape' ? '#FF6E6C' : '#A7A7A7' }} >Landscape</p>
                  </button>
                </div>
              </div>
              <div className='p-3 page-orientation'>
                <h6>Margin</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <button
                    className={`img-body ${formData.margin === 'none' ? 'selected' : ''}`}
                    onClick={(e) => handleMarginSelection(e, 'none')}
                    checked
                  >
                    <img src={noMargin} alt="No margin" />
                    <p className='p-body-a' style={{ color: formData.margin === 'none' ? '#FF6E6C' : '#A7A7A7', fontSize: "16px" }}>No margin</p>
                  </button>
                  <div
                    className={`img-body ${formData.margin === 'small' ? 'selected' : ''}`}
                    onClick={(e) => handleMarginSelection(e, 'small')}
                  >
                    <img src={smallMargin} alt="Small margin" />
                    <p className='l-body-a' style={{ color: formData.margin === 'small' ? '#FF6E6C' : '#A7A7A7' }}>Small</p>
                  </div>
                  <div
                    className={`img-body ${formData.margin === 'big' ? 'selected' : ''}`}
                    onClick={(e) => handleMarginSelection(e, 'big')}
                  >
                    <img src={bigimg} alt="Big margin" />
                    <p className='l-body-a' style={{ color: formData.margin === 'big' ? '#FF6E6C' : '#A7A7A7' }}>Big</p>
                  </div>
                </div>
              </div>
              <div className='mt-3 d-flex gap-3'>
                <p className='w-25 hello'>Page size: </p>
                <select className='form-control' name="pageSize" value={formData.pageSize} onChange={handleChange}>
                  <option value="A4">A4 (595.28, 841.89)</option>
                  <option value="Letter">Letter (612, 792)</option>
                  <option value="Legal">Legal (612, 1008)</option>
                  <option value="Tabloid">Tabloid (792, 1224)</option>
                </select>
              </div>
              <div className="form-check form-switch p-0 d-flex justify-content-between mt-4">
                <p>Merge all images in one PDF file</p>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={formData.isMergeAll}
                  onChange={handleSwitchChange}
                />
              </div>
              <div>
                <button type="submit" className='btn-convert w-100'>
                  Convert to PDF <img src={rightarrow} alt="" width="30" height="18" />
                </button>
              </div>
            </div>
          </div>
        </form>}
      </>}
      {files.length === 0 ? <>
        <FileFooter id={id} />
        <Footer />
      </> : <>Add</>}
      <ToastContainer />
    </>
  )
}
export default PngToPdf