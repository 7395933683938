import React, { useEffect, useState } from 'react'
import "./htmltopdf.css";
import Header from '../../components/header/Header';
import Imagebox from '../../components/pdfConveter/Imagebox';
import smallimg from "../../assets/small-margin-icon.png"
import bigimg from "../../assets/big-margin-icon.png"
import toast, { Toaster } from "react-hot-toast";
import rightarrow from "../../assets/rightarrow.png"
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import { REACT_APP_BASE_URL } from '../../config';
import { useParams, useLocation } from "react-router-dom";
import { Worker, Viewer } from '@react-pdf-viewer/core';

export default function Htmltopdf() {
    const [images, setImages] = useState([])
    const [buttonStatus, setButtonStatus] = useState(false);
    const [loader, setLoader] = useState("initial");
    const [fileUrl, setFileUrl] = useState("");
    const [selectedOrientation, setSelectedOrientation] = useState('');
    const [selectedMargin, setSelectedMargin] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.state?.fileeUrl;
    const fetchUrl = location.state?.url;
    console.log("URL>>>>>>>>", url)
    const { id } = useParams();
    const [formData, setFormData] = useState({
        orientation: '',
        width: '612',
        height: '792',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handlePageOrientationClick = (orientation) => {
        // Handle page orientation selection
        setSelectedOrientation(orientation);
        setFormData((prevData) => ({
            ...prevData,
            orientation: orientation,
        }));
    };

    useEffect(() => {
        if (loader === "end" && buttonStatus && fileUrl) {
            navigate("/convertor/file-ready", { state: { fileUrl: fileUrl } });
        }
    }, [fileUrl, loader, buttonStatus]);

    const handleMarginSelection = (marginType) => {
        // Handle margin type selection
        setSelectedMargin(marginType);
    };
    console.log(formData, 'formData')
    const convertHtmlToPdf = () => {
        setLoader("loading");
        const apiFormData = new FormData();
        images.forEach((image, index) => {
            apiFormData.append(`files`, image.file); // Use a unique key for each image
        })
        apiFormData.append('orientation', formData.orientation);
        apiFormData.append('width', formData.width);
        apiFormData.append('height', formData.height);
        const marginValue = selectedMargin === "small" ? 20 : selectedMargin === "big" ? 50 : 0;
        apiFormData.append('margin', marginValue);
        apiFormData.append('url', fetchUrl);


        const requestOptions = {
            method: "POST",
            body: apiFormData,
            redirect: "follow"
        };

        fetch(
            `${REACT_APP_BASE_URL}converter/html-to-pdf`,
            requestOptions
        )
            .then((res) => res.json())
            .then((res) => {
                console.log(res, "ress");
                setFileUrl(res.uploadedFile);
                setButtonStatus(true); // set this true on statuscode 200
                //  setMessage(res?.message)
                if (res.status) {
                    setLoader("end");
                    toast.success(res?.message);
                } else {
                    setLoader("initial");
                    toast.error(res?.message);
                }
            })
    }
    console.log(images, 'images')


    useEffect(() => {
        console.log(
            loader == "end",
            buttonStatus,
            fileUrl,
            ' (loader == "end" && buttonStatus &&  fileUrl)'
        );
        loader == "end" &&
            buttonStatus &&
            fileUrl &&
            navigate("/convertor/file-ready", { state: { fileUrl: fileUrl } });
    }, [fileUrl, loader, buttonStatus]);
    return (
        <>
            {loader == "loading" ? (
                <Loader />) : (
                <>
                    <Header />
                    <div className='w-100 d-flex justify-content-between main-container flex-wrap' >
                        <div className=' images-side ms-5' >
                            {/* <iframe
                                src={url}
                                className='iframe-preview'
                                title="Webpage Preview"
                            /> */}
                            <img src = {url}  className='preview '></img>
                        </div>
                        <div className='converter-side p-3' >
                            <h1 className='text-center'>{id}</h1>
                            <div className='d-flex align-items-center w-100'>
                                <span className='title-input w-30' style={{ width: "30%" }}>Web URL:</span>
                                <input type="text" placeholder='url' className='input-weburl w-100' value = {fetchUrl}    />
                                <i className="d-flex align-items-center justify-content-center fa-solid fa-arrows-rotate icon-rotate"></i>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-3'>
                                <span className='title-input w-30' style={{ width: "30%" }}>Screen size:</span>
                                <input type="text" placeholder='Your screen (1536px)' className='input-weburl w-100' />
                            </div>
                            <div className='d-flex align-items-center w-100 mt-3'>
                                <span className='title-input w-30' style={{ width: "30%" }}>Page size:</span>
                                <input type="text" placeholder='A4 (297x210 mm)' className='input-weburl w-100' />
                            </div>
                            <div className='p-3 page-orientation'>
                                <h6>Page orientation</h6>
                                <div className='card-body d-flex justify-content-between mt-3'>
                                    {/* <div className='portrait'>
                <p className='p-body'></p>
                <p className='p-body-a'>Portrait</p>
              </div>
              <div className='landscape'>
                <p className='l-body'></p>
                <p className='l-body-a'>Landscape</p>
              </div> */}

                                    <div
                                        className={`portrait ${selectedOrientation === 'portrait' ? 'selected' : ''}`}
                                        onClick={() => handlePageOrientationClick('portrait')}
                                    >
                                        <p className='p-body'></p>
                                        <p className='p-body-a'>Portrait</p>
                                    </div>
                                    <div
                                        className={`landscape ${selectedOrientation === 'landscape' ? 'selected' : ''}`}
                                        onClick={() => handlePageOrientationClick('landscape')}
                                    >
                                        <p className='l-body'></p>
                                        <p className='l-body-a'>Landscape</p>
                                    </div>

                                </div>
                            </div>

                            <div className='p-3 page-orientation'>
                                <h6>Margin</h6>
                                <div className='card-body d-flex justify-content-between mt-3'>
                                    <div
                                        className={`img-body ${selectedMargin === 'no-margin' ? 'selected' : ''}`}
                                        onClick={() => handleMarginSelection('no-margin')} >
                                        <img src={smallimg} alt="logo" />
                                        <p className='p-body-a'>No margin</p>
                                    </div>
                                    <div
                                        className={`img-body ${selectedMargin === 'small' ? 'selected' : ''}`}
                                        onClick={() => handleMarginSelection('small')} >
                                        <img src={smallimg} alt="logo" />
                                        <p className='l-body-a'>Small</p>
                                    </div>
                                    <div
                                        className={`img-body ${selectedMargin === 'big' ? 'selected' : ''}`}
                                        onClick={() => handleMarginSelection('big')} >
                                        <img src={bigimg} alt="logo" />
                                        <p className='l-body-a'>Big</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='mt-3 d-flex gap-3'>
            <p className='w-25 hello'>page size : </p>
            <select className='form-control' name="size" value={formData.size} onChange={handleChange}>
              <option selected hidden>Select size</option>
              <option value="1000x700"> 1000x700</option>
              <option value="A3"> A3 (297*210 mm)</option>
              <option value="A5"> A5 (297*210 mm)</option>
            </select>
          </div> */}
                            <div className='mt-3 d-flex gap-3'>
                                <p className='w-25 hello'>Width: </p>
                                <select className='form-control' name="width" value={formData.width} onChange={handleChange}>
                                    <option selected hidden>Select width</option>
                                    <option value="1000">1000</option>
                                    <option value="2000">2000</option>
                                    <option value="3000">3000</option>
                                </select>
                                <p className='w-25 hello'>Height: </p>
                                <select className='form-control' name="height" value={formData.height} onChange={handleChange}>
                                    <option selected hidden>Select height</option>
                                    <option value="700">700</option>
                                    <option value="1400">1400</option>
                                    <option value="2100">2100</option>
                                </select>
                            </div>

                            <div class="form-check form-switch p-0 d-flex justify-content-between mt-4">
                                <p>Merge all images in one PDF file</p>
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                            </div>

                            <div>
                                <button className='btn-convert w-100' onClick={convertHtmlToPdf}>Convert to  PDF <img src={rightarrow} alt="" width="30" height="18" /> </button>
                            </div>
                        </div>
                    </div>
                </>)
            }
            <Toaster />
        </>
    )
}
