import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import '../privacy-policy/privacypolicy.css'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the window when the component mounts
    });
  return (
    <>
    <Header/>
    <div className='main'>
    <h1 className='heading-about'>PRIVACY POLICY</h1>
    <p className='para-about'>At PDFplex, safeguarding your privacy is our utmost priority. This Privacy Policy elucidates how we gather, employ, and protect the information you furnish while utilizing our services.
    PDFplex operates without requiring user registration. However, during your interaction with our services, we may automatically collect certain information, including:
    </p>
    <span className='para-about'>
    <ul>
        <li><span>Uploaded Files:</span> When you utilize our services, such as converting files from one format to another (e.g., PDF to Word, Excel to PDF, etc.), the files you upload are temporarily stored on our servers for processing purposes.</li>
        <li><span>Usage Information:</span> We may collect data regarding how you interact with our platform, including actions taken and preferences selected.</li>
        <li><span>Device Information:</span> Information about the device and software you utilize to access PDFplex, such as IP address, browser type, and operating system, may be logged for security and analytical purposes.</li>
    </ul>
    </span>
    <h1 className='heading-about'>Utilization of Your Information</h1>
    <p className='para-about'>
    We employ the information gathered for the following purposes:
    </p>
    <span className='para-about'>
        <ul>
            <li><span>Service Provision:</span> Your uploaded files are processed to provide the requested services promptly and efficiently.</li>
            <li><span>Improvement of Services:</span> Analysis of usage patterns and feedback aids us in enhancing and optimizing our services continually.</li>
            <li><span>Communication:</span> We may occasionally send you service-related announcements or respond to inquiries initiated by you.</li>
        </ul>
    </span>
    <h1 className='heading-about'>Data Protection</h1>
    <p className='para-about'>PDFplex employs stringent measures to ensure the security and confidentiality of your data. The files you upload to our platform are automatically deleted after 2 hours to mitigate the risk of unauthorized access or disclosure.
    While we do not share your information with third parties, we may utilize third-party services, such as analytics providers or cloud storage services, to aid in service delivery and improvement. These entities are contractually bound to maintain the confidentiality of your data and are prohibited from using it for any other purposes.
    Although PDFplex does not mandate user registration, you have the liberty to control certain aspects of your interaction with our platform:
    </p>
    <span className='para-about'>
        <ul>
            <li><span>Data Access and Deletion:</span> You can access or delete the files you have uploaded to PDFplex by utilizing the provided features within the service.</li>
            <li><span>Cookies:</span> You may choose to disable cookies through your browser settings, although this may affect the functionality of certain features.</li>
        </ul>
    </span>

    <h1 className='heading-about'>Amendments to This Privacy Policy</h1>
    <p className='para-about'>This Privacy Policy may be periodically updated to reflect alterations in our services or regulatory requirements. 
    We will notify you of significant changes by posting the revised policy on our website.
    </p>

    <h1 className='heading-about'>Contact Us</h1>
    <p className='para-about'>If you have any inquiries or concerns regarding this Privacy Policy or our data practices, please contact us at contact@pdfplex.com. <br/>
    We acknowledge the contribution of privacy policies from industry competitors, which served as a reference in crafting this document.
    </p>

    </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy