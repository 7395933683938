import React, { useState } from 'react';
import FileHeader from '../file-header/FileHeader';
import Imagebox from '../pdfConveter/Imagebox';
import FileFooter from '../file-footer/FileFooter';
import Footer from '../footer/Footer';
import rightarrow from "../../assets/rightarrow.png";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../api/api';
import Loader from '../loader/Loader';

const AddWatermark = ({ id, isSingle }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [watermarkText, setWatermarkText] = useState('');
  const [fontSize, setFontSize] = useState(50);
  const [opacity, setOpacity] = useState(0.3);
  const [watermarkColor, setWatermarkColor] = useState('#FF0000');
  const [degree, setDegree] = useState(45);
  const navigate = useNavigate();

  const convertFile = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Accumulate selected files
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      toast.error("Please select at least one file.");
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append(`file`, files[0]); // Send only the first file for single file upload
    formDataToSend.append('watermarkText', watermarkText);
    formDataToSend.append('fontSize', fontSize);
    formDataToSend.append('opacity', opacity);
    formDataToSend.append('watermarkColor', watermarkColor);
    formDataToSend.append('degree', degree);
    try {
      const response = await api().post('add-watermark/single', formDataToSend);
      setLoading(false);

      if (response?.data?.meta?.status) {
        navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
      } else {
        toast.error("Failed to add watermark to the PDF file.");
      }
    } catch (error) {
      console.error("Error ===> ", error);
      toast.error("An error occurred during the watermarking process.");
      setLoading(false);
    }
  };

  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        multiple={!isSingle}
                        accept=".pdf"
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 25MB. Supported formats are: {id.split("-")[0]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Loader title={"Processing " + id.replace(/-/g, ' ').toUpperCase()} />
          ) : (
            <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
              <div className='images-side ml-5'>
                <Imagebox files={files} setFiles={setFiles} type="pdf" isSingle={isSingle} />
              </div>

              <div className='converter-side p-3 d-flex flex-column justify-content-between' style={{ height: '600px' }}>
                <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
                <form onSubmit={handleSubmit} className='d-flex flex-column gap-3'>
                  <div className="form-group">
                    <label>Watermark Text</label>
                    <input
                      type="text"
                      className="form-control"
                      value={watermarkText}
                      onChange={(e) => setWatermarkText(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Font Size</label>
                    <input
                      type="number"
                      className="form-control"
                      value={fontSize}
                      onChange={(e) => setFontSize(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Rotate Degree</label>
                    <input
                      type="number"
                      className="form-control"
                      value={degree}
                      onChange={(e) => setDegree(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Opacity (0 to 1)</label>
                    <input
                      type="number"
                      step="0.1"
                      className="form-control"
                      value={opacity}
                      onChange={(e) => setOpacity(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Watermark Color</label>
                    <input
                      type="color"
                      style={{height:42}}
                      className="form-control"
                      value={watermarkColor}
                      onChange={(e) => setWatermarkColor(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <button type="submit" className='btn-convert w-100'>
                      Add Watermark <img src={rightarrow} alt="" width="30" height="18" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}

      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default AddWatermark;
