import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import '../about-us/about.css'

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the window when the component mounts
    });
  return (
    <>
    <Header/>
    <div className='main'>
    <p className='para-about'>Welcome to PDFplex! Our About Us page is designed to provide you with insight into who we are, our mission, and the values that drive us. Get to know the team behind PDFplex and learn more about our commitment to providing high-quality document conversion and processing services.</p>
    <h1 className='heading-about'>Our Mission:</h1>
    <p className='para-about'>At PDFplex, our mission is to simplify and streamline the document conversion and processing process for individuals and businesses worldwide. We aim to empower users with efficient and user-friendly tools that enable them to convert, compress, and manipulate documents with ease.
    Our vision is to become a leading provider of online document conversion and processing services, recognized for our commitment to innovation, reliability, and customer satisfaction. We aspire to continuously improve and expand our services to meet the evolving needs of our users.
    </p>
    <h1 className='heading-about'>Our Values:</h1>
    <p className='para-about'>
    At PDFplex, we are guided by the following core values:
    Quality: We are committed to delivering high-quality services that meet the needs and expectations of our users.
    Innovation: We strive to innovate and develop new solutions to enhance the efficiency and effectiveness of our services.
    Integrity: We conduct ourselves with honesty, transparency, and integrity in all our interactions with users, partners, and stakeholders.
    Customer Focus: We prioritize the needs and preferences of our users and are dedicated to providing exceptional customer service and support.
    Privacy and Security: We prioritize the privacy and security of user data and adhere to strict data protection measures to safeguard user information.
    </p>
    <h1 className='heading-about'>Our Team:</h1>
    <p className='para-about'>
        PDFplex is powered by a team of dedicated professionals with expertise in software development, data engineering, and customer support. Our team is passionate about technology and is committed to delivering innovative solutions that make document conversion and processing effortless.
    </p>
    <h1 className='heading-about'>Thank You for Choosing PDFplex:</h1>
    <p className='para-about'>We appreciate your trust and confidence in PDFplex. Thank you for choosing us as your partner for all your document conversion and processing needs. We look forward to serving you and helping you achieve your goals with our services.
    </p>
    </div>
    <Footer/>
    </>
  )
}

export default About