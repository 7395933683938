import React from "react";
import "./files-detail.css";
import Header from "../../components/header/Header";

const FilesDetail = () => {
    return (
        <>
            <Header />
            <div className="p-5 main">
                <div className="d-flex main-Screen justify-content-between">
                    <div className="left-side">
                        <div className="cards-section">
                            <div className="card">
                                <div>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mt-2">
                                        <div>
                                            <i className="fa fa-solid fa-rotate"></i>
                                        </div>
                                        <div>
                                            <i className="fa fa-solid fa-circle-xmark" style={{color: "#2a2a27;"}}></i>
                                        </div>
                                    </div>
                                    <img
                                        src="https://img.freepik.com/free-photo/new-york-city_649448-1679.jpg?w=1380&t=st=1710180388~exp=1710180988~hmac=e14cd1e474e8f3650453fac9e9a625b0a341832ae1fd8a3000eab60cff1a925b"
                                        alt="" />
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mt-2">
                                        <div>
                                            <i className="fa fa-solid fa-rotate"></i>
                                        </div>
                                        <div>
                                            <i className="fa fa-solid fa-circle-xmark" style={{color: "#2a2a27;"}}></i>
                                        </div>
                                    </div>
                                    <img
                                        src="https://img.freepik.com/free-photo/new-york-city_649448-1679.jpg?w=1380&t=st=1710180388~exp=1710180988~hmac=e14cd1e474e8f3650453fac9e9a625b0a341832ae1fd8a3000eab60cff1a925b"
                                        alt="" />
                                </div>
                            </div>
                            <div className="card-one">
                                <img
                                    src="../../../assets/images/company-profile/add-icon.svg"
                                    alt="" />
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Add more</option>
                                    <option value="1">Upload from Computer</option>
                                    <option value="2">Select from Google Drive</option>
                                    <option value="3">Select from DropBox</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="right-side p-4">
                        <div className="mt-5">
                            <h2>
                                Merge PDF
                            </h2>
                            <p className="text-start mt-4">
                                Please select more PDF files by clicking again on “Select PDF files”. <br />
                                Select multiple files by maintaining pressed “Ctrl”</p>
                        </div>
                        <button>
                            <div className="d-flex gap-4">
                                <div>Merge PDF</div>
                                <div> <i className="fa fa-light fa-arrow-right fa-sm"></i></div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

        </>

    )
}

export default FilesDetail;