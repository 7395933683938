import React, { useRef, useState, useEffect } from 'react';
import './imagebox.css';
import pluslogo from '../../assets/add-icon.png';
import rotateicon from '../../assets/rotate-icon.svg';
import { readFileAsDataURL } from '../../shared';
import { checkType } from '../../shared'
// Define the Imagebox component
const Imagebox = ({ files, setFiles, type, isSingle }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const ref = useRef();
  let resType = checkType(type);
  // Handle adding more images
  const addMore = () => {
    ref.current.click();
  };

  // Handle file changes
  const fileChange = (event) => {
    const files = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...files]);
  };

  // Remove image and clean up URL
  const removeImage = (index) => {
    setFiles((file) =>
      file.filter((_, i) => i !== index)
    );
  };

  // Rotate image
  const rotateImage = (id) => {
  };

  useEffect(() => {
    const loadImagePreviews = async () => {
      try {
        const previews = await Promise.all(files.map(file => readFileAsDataURL(file)));
        setImagePreviews(previews);
      } catch (error) {
        console.error('Error reading files:', error);
      }
    };
    if (files.length > 0) {
      loadImagePreviews();
    } else {
      setImagePreviews([]);
    }

  }, [files]);

  return (
    <>
      <div className="images gap-4 justify-content-center">
        {imagePreviews && imagePreviews.length > 0 ? (
          imagePreviews.map((item, index) => (
            <div className="card d-flex align-item-center justify-content-center" key={index}>
              <div className="d-flex justify-content-end">
                <form>
                <div>
                  <button onClick={(e) =>{e.preventDefault(); rotateImage(index)}} className="remove-button d-none">
                    <img src={rotateicon} alt="Rotate" />
                  </button>
                </div>
                <div>
                  <button onClick={(e) => {e.preventDefault(); removeImage(index)}} className="remove-button">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="10" fill="#000" />
                      <path d="M8 8L16 16M8 16L16 8" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
                </form>
              </div>
              <div
                className="card-body border border-danger border-0 justify-content-center">
                <img
                  src={resType?resType:item}
                  className="card-img-top"
                  alt="..."
                  style={{ transform: `rotate(${item.rotation}deg)`, transition: 'transform 0.3s ease', width: "150px", height: "150px" }}
                />
              </div>
              <p className="text-center text-muted">file_{index+1}</p>
            </div>
          ))
        ) : (
          <></>
        )}
        {!isSingle && <div className="addmore-button d-flex align-items-center">
          <div className="card-one" onClick={addMore}>
            <img src={pluslogo} alt="Add More" />
            <input
              type="file"
              // accept={filetype === "merging" ? "application/pdf" : "image/*"}
              accept={"image/*"}
              multiple
              ref={ref}
              onChange={fileChange}
            />
          </div>
        </div>}
      </div>
    </>
  );
};

export default Imagebox;
