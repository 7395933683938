// import React from "react";
  import "./loader.css"; 
import React from 'react';
import loadingImage from '../../assets/loading-animation.gif'; // Replace 'loading.gif' with your actual loading image path

const Loader = ({title}) => {
  return (
    <div className="loader-container d-flex flex-column gap-3">
      <img src={loadingImage} height={100} alt="Loading..." className="" />
      <span className= 'loader-title'>{title}...</span>
      <span className="loader-desc">This might take a few seconds...</span>
    </div>
  );
};

export default Loader;