import React from "react";
import { features } from "../../utils";
import { Link, useLocation } from 'react-router-dom';
import signupImage from '../../assets/signup.svg'; // Make sure to import the correct image

const Feature = ({ id }) => {
  const filteredFeatures = features.filter(data => data.id !== id);
  return (
    <div>
      <h1 className="featured-heading">Featured</h1>
      <div className="itemBox d-flex flex-wrap gap-4">
        {filteredFeatures?.map((feature, index) => (
          <Link 
            key={index}
            className="featuredBox p-4" 
            to={`/convertor/${feature.id}`} // Use the function to generate the URL
          >
            <img src={feature.image || signupImage} className="App-logo img-fluid" alt="logo" />
            <h1>{feature.name}</h1>
            <span className="description text-muted">{feature.description}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Feature;
