import React, { useState, useRef } from "react";
import "../header/header.css";
import "../../App.css";
import Logo from "../../assets/logotext.png";
import { Link } from 'react-router-dom';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import mergePdf from "../../assets/merge PDF.svg";
import pdfConverter from "../../assets/pdf converter.svg";
import compressPdf from "../../assets/compress pdf.svg";

function Header() {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef(null);

    const handleMouseEnter = () => setIsPopoverOpen(true);
    const handleMouseLeave = () => setIsPopoverOpen(false);

    const handleLinkClick = () => setIsPopoverOpen(false);  // Handler to close the popover when clicking any button

    return (
        <div className="fixed-top top-nav">
            <header className="header w-100">
                <Link to="/">
                    <img src={Logo} className="image-logo" alt="logo" />
                </Link>

                <div
                    className="popover-container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <OverlayTrigger
                        show={isPopoverOpen}
                        trigger="manual"
                        placement="bottom"
                        overlay={
                            <Popover id="popover-content" ref={popoverRef}>
                                <Popover.Body className="d-flex flex-column gap-3">
                                    <div className="d-flex gap-5 p-2 flex-wrap">
                                        <div>
                                            <div className="item-nav mb-3">
                                                Optimize PDF
                                            </div>
                                            <div className="d-flex flex-column gap-3">
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/" onClick={handleLinkClick}>
                                                    <img src={mergePdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Merge PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">Split Pages</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-compress" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Compress PDF</div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="item-nav mb-3">
                                                Convert to PDF
                                            </div>
                                            <div className="d-flex flex-column gap-3">
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/jpg-to-pdf" onClick={handleLinkClick}>
                                                    <img src={mergePdf} height={20} alt="" />
                                                    <div className="dropdown-ele">JPG to PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/img-to-pdf" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">Image to PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-compress" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">JPG to PNG</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/word-to-pdf" onClick={handleLinkClick}>
                                                    <img src={mergePdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Word to PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/xls-to-pdf" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">XLS to PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/html-to-pdf" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">HTML to PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/ppt-to-pdf" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">PPT to PDF</div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="item-nav mb-3">
                                                Convert from PDF
                                            </div>
                                            <div className="d-flex flex-column gap-3">
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-jpg" onClick={handleLinkClick}>
                                                    <img src={mergePdf} height={20} alt="" />
                                                    <div className="dropdown-ele">PDF to JPG</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-image" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">PDF to Image</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-word" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">PDF to Word</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-xls" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">PDF to XLS</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-ppt" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">PDF to PPT</div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="item-nav mb-3">
                                                Edit PDF
                                            </div>
                                            <div className="d-flex flex-column gap-3">
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-ppt" onClick={handleLinkClick}>
                                                    <img src={mergePdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Rotate PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-ppt" onClick={handleLinkClick}>
                                                    <img src={pdfConverter} height={20} alt="" />
                                                    <div className="dropdown-ele">Add Watermark</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Edit PDF</div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="item-nav mb-3">
                                                PDF Security
                                            </div>
                                            <div className="d-flex flex-column gap-3">
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/convertor/pdf-to-ppt" onClick={handleLinkClick}>
                                                    <img src={mergePdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Protect PDF</div>
                                                </Link>
                                                <Link className="d-flex gap-2 align-items-center btn-element" to="/" onClick={handleLinkClick}>
                                                    <img src={compressPdf} height={20} alt="" />
                                                    <div className="dropdown-ele">Sign PDF</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <Button className="btn-header bg-transparent border-0">
                            All PDF Tools &nbsp;<i class="fa-sharp fa-solid fa-chevron-down"></i>
                        </Button>
                    </OverlayTrigger>
                </div>

                <div className=""></div>
            </header>
        </div>
    );
}

export default Header;
