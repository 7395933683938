import mergePdf from "./assets/merge PDF.svg";
import pdfConverter from "./assets/pdf converter.svg";
import compressPdf from "./assets/compress pdf.svg";
import React from 'react';
 

export const SvgComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: 'auto',
        background: 'white',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <defs>
        <mask id="ldio-flw08ph75pu-mask">
          <circle cx="50" cy="50" r="45" fill="#fff"></circle>
        </mask>
      </defs>
      <circle cx="50" cy="50" r="45" fill="#fff9f5"></circle>
      <g mask="url(#ldio-flw08ph75pu-mask)">
        <g transform="translate(50 50)">
          <g transform="scale(0.5)">
            <g transform="translate(-50 -50)">
              <g transform="translate(25 25)">
                <g>
                  <path
                    d="M -20 20 L 20 -20 L 120 80 L 80 120 L -20 20"
                    fill="#ff9f5f"
                  ></path>
                  <rect x="-20" y="-20" width="40" height="40" fill="#1d0e0b"></rect>
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="1.7857142857142856s"
                    keyTimes="0;1"
                    values="1.5;1"
                    keySplines="0 0.5 0.5 1"
                    calcMode="spline"
                    begin="-0.5357142857142857"
                  ></animateTransform>
                </g>
              </g>
              <g transform="translate(75 25)">
                <g>
                  <path
                    d="M -20 20 L 20 -20 L 120 80 L 80 120 L -20 20"
                    fill="#ff9f5f"
                  ></path>
                  <rect x="-20" y="-20" width="40" height="40" fill="#774023"></rect>
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="1.7857142857142856s"
                    keyTimes="0;1"
                    values="1.5;1"
                    keySplines="0 0.5 0.5 1"
                    calcMode="spline"
                    begin="-0.35714285714285715"
                  ></animateTransform>
                </g>
              </g>
              <g transform="translate(25 75)">
                <g>
                  <path
                    d="M -20 20 L 20 -20 L 120 80 L 80 120 L -20 20"
                    fill="#ff9f5f"
                  ></path>
                  <rect x="-20" y="-20" width="40" height="40" fill="#d88c51"></rect>
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="1.7857142857142856s"
                    keyTimes="0;1"
                    values="1.5;1"
                    keySplines="0 0.5 0.5 1"
                    calcMode="spline"
                    begin="0"
                  ></animateTransform>
                </g>
              </g>
              <g transform="translate(75 75)">
                <g>
                  <path
                    d="M -20 20 L 20 -20 L 120 80 L 80 120 L -20 20"
                    fill="#ff9f5f"
                  ></path>
                  <rect x="-20" y="-20" width="40" height="40" fill="#f3e7c9"></rect>
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="1.7857142857142856s"
                    keyTimes="0;1"
                    values="1.5;1"
                    keySplines="0 0.5 0.5 1"
                    calcMode="spline"
                    begin="-0.17857142857142858"
                  ></animateTransform>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

 

export  const features = [
    { id: "jpg-to-pdf", name: "JPG to PDF", image: mergePdf, description: "This free tool allows you to combine several JPG files into one PDF file." },
    { id: "pdf-to-jpg", name: "PDF to JPG", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },   
    { id: "pdf-to-word", name: "PDF to Word", image: pdfConverter, description: "Using PDFPlex, you can convert PDF documents seamlessly into editable Word files." },  
    { id: "pdf-to-image", name: "PDF to Image", image: pdfConverter, description: "Separate one page whole set for easy conversion into independent PDF files." },   
    { id: "html-to-pdf", name: "HTML to PDF", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },  
    { id: "png-to-pdf", name: "PNG to PDF", image: mergePdf, description: "Easy and quick conversion of PNG files to PDF with this user-friendly web app." },
    { id: "pdf-to-ppt", name: "PDF to PPT", image: pdfConverter, description: "Separate one page whole set for easy conversion into independent PDF files." },
    { id: "pdf-compress", name: "Compress PDF", image: compressPdf, description: "With this online PDF compression tool, you can reduce the size of your PDF files. Upload PDF files to Pdfplex to quickly compress them." },
    { id: "png-to-jpg", name: "PNG to JPG", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },
    { id: "word-to-pdf", name: "Word to PDF", image: pdfConverter, description: "You can convert your MS Word file into PDF with this tool. No installation is required to convert your Doc file to PDF online, free and easily." },
    { id: "xls-to-pdf", name: "XLS to PDF", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },
    { id: "pdf-to-xls", name: "PDF to XLS", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },
    { id: "ppt-to-pdf", name: "PPT to PDF", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },
    { id: "img-to-pdf", name: "Image to PDF", image: pdfConverter, description: "Separate one page whole set for easy conversion into independent PDF files." },
    { id: "pdf-password", name: "PDF Password", image: pdfConverter, description: "Setup the password of pdf files which will protect you'r pdf file." },
    { id: "merge-pdf", name: "Merge PDF", image: mergePdf, description: "Combine PDFs in the order you want with the easiest PDF merger available." },
    { id: "rotate-pdf", name: "Rotate PDF", image: mergePdf, description: "Rotate PDFs in the order you want with." },
    { id: "split-pdf", name: "Split PDF", image: mergePdf, description: "Split PDF by converting each page to a separate pdf file." },
    { id: "add-watermark", name: "Add Watermark", image: mergePdf, description: "Add watermark on each page of pdf file" },

  ];

  