import pdfIcon from './assets/pdf-icon.svg'
import docIcon from './assets/doc-icon.svg'
import pptIcon from './assets/ppt-icon.svg'
import xlsIcon from './assets/xls-icon.svg'

export const replaceId = (id) => {
    return id.replaceAll("-", " ");
}

export const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  export const checkType = (type) => {
    if(type === 'pdf'){
        return pdfIcon
    } else if(type === 'doc'){
        return docIcon
    } else if(type === 'ppt'){
        return pptIcon
    } else if(type === 'xls'){
        return xlsIcon
    } else{
        return false
    }
  };

 export const MAX_FILE_SIZE = 10 * 1024 * 1024; 