import axios from "axios"
export const api = () => {
    return axios.create({
        // baseURL:"http://localhost:3000/api/",
        baseURL:"https://api.pdfplex.com/api/",
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data"
        }
    })
}

export const apiJson = () => {
    return axios.create({
        // baseURL:"http://localhost:3000/api/",
        baseURL:"https://api.pdfplex.com/api/",
        headers: {
            Accept: "application/json",
           'Content-Type': 'application/json',
        }
    })
}