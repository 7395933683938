import React, { useState, useRef } from "react";
import rightarrow from "../../assets/rightarrow.png";
import "../../pages/cutomize/customize-page.css";
import noMargin from "../../assets/marginsetIcon.svg";
import bigimg from "../../assets/big-margin-icon.png";
import smallMargin from "../../assets/small-margin-icon.svg";
import { api, apiJson } from "../../api/api";
import { useNavigate } from "react-router-dom";
import Loader from '../loader/Loader';
import { replaceId } from "../../shared";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HtmlToPdf = ({ id }) => {
  // const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [orientation, setOrientation] = useState('portrait');
  const [margin, setMargin] = useState('none');
  const [loading, setLoading] = useState(false);
  const [showConvertSection, setShowConvertSection] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [formData, setFormData] = useState({
    url: '',
    screenSize: '1536px',
    pageSize: 'A4 (297x210 mm)',
    oneLongPage: true,
    orientation,
    margin
  });

  const navigate = useNavigate();
  const formRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (formData.url || files.length > 0) {
      setShowConvertSection(true);
    } else {
      toast.error("Please provide either a URL or upload a file.")
    }
  };

  const handleConvertToPdf = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formData.url) {
      let jsonData = JSON.stringify(formData);
      try {
        const response = await apiJson().post(
          `${id}/url`, jsonData
        );
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
        }
      } catch (error) {
        console.log("Error ===> ", error);
        throw error;
      }
    } else if (files.length > 0) {
      const formDataToSend = new FormData();
      Object.keys(formData)?.forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
      try {
        formDataToSend.append('file', files[0]);
        const response = await api().post(
          `${id}/single`, formDataToSend
        );
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
        }
      } catch (error) {
        toast.error(error.message);
        throw error;
      }
    } else {
      toast.error("Please provide either a URL or upload a file.")
      return;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFiles([file]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setHtmlContent(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      {loading ? (
        <Loader title={"Converting " + replaceId(id)} />
      ) : (
        <>
          {!showConvertSection ? (
            <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
              <div className='images-side d-flex justify-content-center align-items-center w-100' style={{ height: "100vh" }}>
                <div className="popup-box p-5 d-flex flex-column gap-3" style={{ height: "70vh" }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div></div>
                    <span className="modal-heading">Add HTML to Convert From</span>
                    <i style={{ fontSize: "20px" }}></i>
                  </div>
                  <div className="pt-2 pb-2 d-flex flex-column gap-2">
                    <span className="modal-input-title">Paste URL</span>
                    <div className="d-flex w-100">
                      <input
                        className="input-modal w-100 p-3"
                        type="text"
                        name="url"
                        placeholder="https://pdfplex.com"
                        value={formData.url}
                        onChange={handleChange}
                        required
                      />
                      <i className="modal-icon fa-solid fa-globe d-flex align-items-center justify-content-center"></i>
                    </div>
                  </div>
                  <ToastContainer />
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <hr className="w-50" style={{ color: "1px solid #E9E9E9" }} />
                    <span className="or">OR</span>
                    <hr className="w-50" style={{ color: "1px solid #E9E9E9" }} />
                  </div>
                  <div className="pt-2 pb-2 d-flex flex-column gap-2">
                    <span className="modal-input-title">Upload html file</span>
                    <div>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      <button
                        className="upload-btn d-flex align-items-center justify-content-center p-4"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <i className="fa-solid fa-arrow-up-from-bracket">&nbsp;</i> &nbsp;&nbsp;&nbsp;Click to upload
                      </button>
                    </div>
                    <span className="modal-desc mt-3">Maximum size allowed is 25MB. Supported formats are :html</span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn-add" type="button" onClick={handleButtonClick}>Add</button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <form ref={formRef} onSubmit={handleConvertToPdf}>
              <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
                <div className='images-side ml-5' style={{ marginTop: "60px" }}>
                  {formData.url && (
                    <iframe
                      src={formData.url}
                      style={{ width: '100%', height: '1000px', border: 'none' }}
                      title="Website Viewer"
                    />
                  )}
                  {htmlContent && (
                    <iframe
                      srcDoc={htmlContent}
                      style={{ width: '100%', height: '1000px', border: 'none' }}
                      title="HTML Preview"
                    />
                  )}
                </div>
                <div className='converter-side p-3 d-flex flex-column justify-content-between'>
                  <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
                  <div>
                    <div className="containerr pt-2 pb-2 d-flex align-items-center gap-2">
                      <span className="form-title w-50">Web URL:</span>
                      <input
                        className="input-form w-50 p-3"
                        type="text"
                        name="url"
                        placeholder="https://pdfplex.com"
                        value={formData.url}
                        onChange={handleChange}
                      />
                      <i className="refresh-icon fa-solid fa-arrows-rotate d-flex align-items-center justify-content-center"></i>
                    </div>
                    <div className="containerr d-flex align-items-center justify-content-between gap-2 mt-2 pb-2" style={{ borderTop: "0px" }}>
                      <p className="form-title w-50">Screen size: </p>
                      <select
                        className='input-formm p-3'
                        name="screenSize"
                        style={{ borderRadius: "8px" }}
                        value={formData.screenSize}
                        onChange={handleChange}
                        required
                      >
                        <option value="1536px">Your screen (1536px)</option>
                        <option value="1436px">Your screen (1436px)</option>
                        <option value="1336px">Your screen (1336px)</option>
                      </select>
                    </div>
                    <div className="containerr d-flex flex-column" style={{ borderTop: "0px" }}>
                      <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                        <p className="form-title w-50">Page size:</p>
                        <select
                          className='input-formm p-3'
                          name="pageSize"
                          style={{ borderRadius: "8px" }}
                          value={formData.pageSize}
                          onChange={handleChange}
                          required
                        >
                          <option value="A4">A4 (595.28, 841.89)</option>
                          <option value="Letter">Letter (612, 792)</option>
                          <option value="Legal">Legal (612, 1008)</option>
                          <option value="Tabloid">Tabloid (792, 1224)</option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center justify-content-end gap-2 p-3">
                        <span className="title-desc">One long page</span>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="oneLongPage"
                          checked={formData.oneLongPage}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='p-3 page-orientation mb-2'>
                        <h6>Page orientation</h6>
                        <div className='card-body d-flex justify-content-between mt-3'>
                          <button
                            type="button"
                            className={`portrait ${orientation === 'portrait' ? 'selected' : ''}`}
                            onClick={() => setOrientation('portrait')}
                          >
                            <p className='p-body'></p>
                            <p className='p-body-a' style={{ color: orientation === 'portrait' ? '#FF6E6C' : '#A7A7A7' }}>Portrait</p>
                          </button>
                          <button
                            type="button"
                            className={`landscape ${orientation === 'landscape' ? 'selected' : ''}`}
                            onClick={() => setOrientation('landscape')}
                          >
                            <p className='l-body'></p>
                            <p className='l-body-a' style={{ color: orientation === 'landscape' ? '#FF6E6C' : '#A7A7A7' }}>Landscape</p>
                          </button>
                        </div>
                      </div>
                      <div className='p-3 page-orientation'>
                        <h6>Margin</h6>
                        <div className='card-body d-flex justify-content-between mt-3'>
                          <button
                            type="button"
                            className={`img-body no-margin ${margin === 'no-margin' ? 'selected' : ''}`}
                            onClick={() => setMargin('no-margin')}
                          >
                            <img src={noMargin} alt="No margin" />
                            <p className='p-body-a' style={{ color: margin === 'no-margin' ? '#FF6E6C' : '#A7A7A7' }}>No margin</p>
                          </button>
                          <div
                            className={`img-body small ${margin === 'small' ? 'selected' : ''}`}
                            onClick={() => setMargin('small')}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={smallMargin} alt="Small margin" />
                            <p className='l-body-a' style={{ color: margin === 'small' ? '#FF6E6C' : '#A7A7A7' }}>Small</p>
                          </div>
                          <div
                            className={`img-body big ${margin === 'big' ? 'selected' : ''}`}
                            onClick={() => setMargin('big')}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={bigimg} alt="Big margin" />
                            <p className='l-body-a' style={{ color: margin === 'big' ? '#FF6E6C' : '#A7A7A7' }}>Big</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <button className='btn-convert w-100' type="submit">
                      Convert to PDF <img src={rightarrow} alt="" width="30" height="18" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default HtmlToPdf;
